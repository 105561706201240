<script setup lang="ts">
  import { UnifiedErrors } from '~/types';

  const props = withDefaults(
    defineProps<{
      errors: UnifiedErrors;
      blacklist?: string[];
      isWhitelist?: boolean;
    }>(),
    { blacklist: () => [], isWhitelist: false },
  );

  const relatedErrors = computed(() =>
    props.errors
      ? Object.keys(props.errors)
          .filter((key) =>
            props.isWhitelist
              ? props.blacklist.includes(key)
              : !props.blacklist.includes(key),
          )
          .map((key) => props.errors[key])
          .flat()
      : [],
  );
</script>

<template>
  <ul v-if="relatedErrors.length">
    <li
      v-for="error in relatedErrors"
      :key="error"
      class="text-14 text-red"
    >
      {{ error }}
    </li>
  </ul>
</template>
